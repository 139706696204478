<template>
  <div>
    <apexchart
      type="line"
      :height="height"
      :width="width"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    currency: {
      type: String,
      default() {
        return "";
      },
    },
    dataDisplay: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: [String, Number],
      default: 400,
    },
    width: {
      type: [String, Number],
      default: 500,
    },
  },
  data() {
    return {
      series: [], // Data series for the chart
      chartOptions: {
        chart: {
          type: "line",
          toolbar: {
            show: true,
            tools: {
              zoom: false, // Disable zoom tool
              zoomin: false, // Disable zoom-in tool
              zoomout: false, // Disable zoom-out tool
              pan: false, // Disable pan tool
            },
          },
        },
        dataLabels: {
          enabled: false, // Disable data labels for clarity
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        markers: {
          size: 5
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#436de7'], // Second color of gradient
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.2,
            stops: [0, 100],
          }
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          markers: {
            width: 8,
            height: 8,
            radius: 4,
          },
          labels: {
            useSeriesColors: true,
            style: {
              fontSize: "11px",
              fontWeight: "bold",
            },
          },
          showForSingleSeries: true,
        },
        tooltip: {
          theme: "light",
          custom: ({ w, dataPointIndex }) => {
            const tooltipContent = w.config.series
              .map((series, i) => {
                // Check if the series is visible
                if (w.globals.collapsedSeriesIndices.includes(i)) return "";

                const seriesBaseName = series.baseName || "No Name";
                const pointValue = w.globals.series[i][dataPointIndex];
                return `
          <div class="legendHoverDataWrap">
            <span style="background-color: ${w.config.colors[i]}; width: 10px; height: 10px; border-radius: 50%; margin-right: 6px;"></span>
            <div class="pointValue">
              ${seriesBaseName} <span class="currencyValue">${this.currency}</span>: <strong>${pointValue}</strong>
            </div>
          </div>
        `;
              })
              .filter((content) => content) // Remove empty strings
              .join("");

            const dateRange = (() => {
              const startDate = this.data.electricity_cost_chart[dataPointIndex]?.period_start_date;
              const endDate = this.data.electricity_cost_chart[dataPointIndex]?.period_end_date;
              const startFormatted = moment(startDate).format("MM/DD");
              const endFormatted = moment(endDate).format("MM/DD");
              return `${startFormatted} - ${endFormatted}`;
            })();

            return `
      <div class="customHoverLegendWrap">
        <div class="dateTimeTextWrap">${dateRange}</div>
        ${tooltipContent}
      </div>
    `;
          },
          x: {
            formatter: (val, opts) => {
              const { dataPointIndex } = opts;
              const startDate = this.data.electricity_cost_chart[dataPointIndex]?.period_start_date;
              const endDate = this.data.electricity_cost_chart[dataPointIndex]?.period_end_date;
              const startFormatted = moment(startDate).format("MM/DD");
              const endFormatted = moment(endDate).format("MM/DD");

              return `${startFormatted} - ${endFormatted}`;
            },
          },
        },
        xaxis: {
          categories: [],
          tooltip: {
            enabled: false, // Disables the tooltip at the bottom on the x-axis
          },
          labels: {
            rotate: -45,
            style: {
              fontSize: "18px",
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            // formatter: (value) => value.toLocaleString(),
            style: {
              fontSize: "10px",
            },
          },
        },
        colors: [],

      },
    };
  },

  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.updateChartData(newData);
        }
      },
    },
  },

  methods: {
    xAxisValues(start) {
      const startDate = new Date(start);
      let dateFormat = "MM/DD";
      if (startDate.getFullYear() !== new Date().getFullYear()) {
        dateFormat = "YYYY/MMM/DD";
      }
      return moment(start).format(dateFormat);
    },
    updateChartData(data) {
      this.series = [];
      let xAxisSource = null;
      const seriesColors = {
        "Electricity Cost": "#5D87FF",
        "Fuel Cost": "#FFAE20",
        "Savings": "#23bdaa",
      };
      if (this.dataDisplay.includes('electricity_cost')) {
        this.series.push({
          name: `Electricity Cost  ${data.electricity_cost} ${this.currency}`,
          data: data.electricity_cost_chart.map((item) => item.cost),
          baseName: "Electricity Cost",
        });
      }
      if (this.dataDisplay.includes('fuel_cost')) {
        this.series.push({
          name: `Fuel Cost   ${data.fuel_cost} ${this.currency}`,
          data: data.fuel_cost_chart.map((item) => item.cost),
          baseName: "Fuel Cost",
        });
      }
      if (this.dataDisplay.includes('savings')) {
        this.series.push({
          name: `Savings   ${data.savings} ${this.currency} `,
          data: data.savings_chart.map((item) => item.cost),
          baseName: "Savings",
        });
      }

      if (data.electricity_cost_chart?.length) {
        xAxisSource = data.electricity_cost_chart;
      } else if (data.fuel_cost_chart?.length) {
        xAxisSource = data.fuel_cost_chart;
      } else if (data.savings_chart?.length) {
        xAxisSource = data.savings_chart;
      }
      if (xAxisSource) {
        this.chartOptions.xaxis.categories = xAxisSource.map((item) =>
          this.xAxisValues(item.period_start_date)
        );
      }
      this.chartOptions.colors = this.series.map((s) => seriesColors[s.baseName] || "#000000");
      // this.chartOptions.markers.colors = this.series.map((s) => seriesColors[s.name] || "#000000");
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

:deep .apexcharts-legend-text
  font-weight: bold !important
  font-size: 11px !important
  margin-left: -13px !important
:deep .apexcharts-legend-marker
  height: 10px !important
  width: 10px !important
:deep .apexcharts-legend-marker svg
  height: 100% !important
  width: 100% !important
  border-radius: 100%
:deep .customHoverLegendWrap
  padding: 0px
  border-radius: 5px
  background-color: white
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2)
:deep .dateTimeTextWrap
  margin-bottom: 10px
  font-size: 11px
  color: #000
  text-align: left
  background: #eceff1
  padding: 0.5rem
  font-family: $poppinReg

:deep .pointValue
  font-size: 11px
  color: #000
  font-family: $poppinReg
  text-align: left
  span.currencyValue
    font-size: 10px
    color: #000
    font-family: $poppinReg
:deep .legendHoverDataWrap
  display: flex
  align-items: center
  margin-bottom: 4px
  padding: 0 0.5rem
</style>